/* styles */
/* test from css */
/* @tailwind base; */
/* @tailwind components; */
@tailwind utilities;

img {
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

.textwrapper {
  margin-bottom: 0px;
}
#withdraw_mobile {
  display: none;
}
.textblock strong {
  font-size: 17px !important;
  margin-top: 30px;
  font-family: "product-sans-bold";
}
.page-rtl .textblock strong {
  font-family: "swissra-bold";
}
.textblock a {
  transition: 0.2s ease-in-out;
  color: var(--iq-pink);
  font-size: 17px !important;
}

.textblock a:hover {
  color: var(--iq-orange);
}

.textblock strong {
  margin-bottom: 20px;
}

.pagebanner {
  margin: 20px 0 30px;
  position: relative;
}

.img_Size {
  height: auto;
}

h2.page-sub-title,
.textblock h2,
.textblock h1 {
  font-size: 28px !important;
  color: var(--iq-black);
  margin: 0.5rem 0px;
  font-family: "Product-Sans-Bold";
}
.page-faq .pageheader p,
.pageheader,
.pageheader p {
  font-size: 28px !important;
}
.page-rtl h2.page-sub-title,
.page-rtl .textblock h2,
.page-rtl .textblock h1 {
  font-family: "swissra-bold";
}

.textblock {
  margin: 0px 0 20px 0;
}

.page-rtl .arabicurr {
  display: flex;
  flex-direction: row-reverse;
}
.page-rtl .arabicurr > .digits-iq-bld:first-child {
  font-family: Swissra-Bold !important;
}

.textblock h5 {
  font-size: 28px;
  color: var(--iq-darkblack);
  margin-bottom: 25px;
}

.textblock p {
  font-size: 17px;
  line-height: 33px;
  text-align: justify;
  margin-bottom: 10px;
}

.winners .pageheader,
.pageheader p {
  margin: 30px 0 30px 0;
}

.regular-iq {
  font-family: "Product-Sans-Regular" !important;
}

.winners {
  display: flex;
  flex-wrap: wrap;
}

#play_responsibly-mobile,
#fraud-awareness-mobile,
#live_draw-mobile,
#about_mobile,
#buy-credit-mobile,
#how-play-mobile {
  display: none;
}

.pagination {
  margin-top: 16px;
}

.pagination ul {
  padding-left: 0;
  display: flex;
  margin: 0;
  justify-content: center;
}

.pagination ul li {
  list-style-type: none;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: relative;
}

.pagination ul li:not(:last-child) {
  margin-right: 7px;
}

.pagination ul li a {
  font-size: 12px;
  font-family: Product-Sans-Bold !important;
  color: var(--iq-darkblack);
  padding: 11px 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--iq-orange);
  background-color: #f6f6f6;
  padding: 0;
  cursor: pointer;
}

.pagination ul li a:hover {
  border-color: var(--iq-orange);
}

.pagination ul li.active a {
  background-color: var(--iq-orange);
  font-family: Product-Sans-Bold;
  color: var(--iq-white);
  border-color: var(--iq-orange);
}

.pagination ul li a i {
  color: var(--iq-darkblack);
  margin-bottom: 5px;
}

.pagination .disabled {
  opacity: 0.5;
  pointer-events: none;
  display: none !important;
  font-family: Product-Sans-Regular;
}

.ffv span {
  font-family: Product-Sans-Regular !important;
}

.row_padding {
  padding: 10px 0px;
}

html[lang="ar"] ol {
  list-style: disc !important;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 1228px) {
  .btn--terciary p {
    font-size: 17px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .video iframe {
    width: 80%;
  }
  .pagebanner {
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .pagination ul li {
    width: 30px;
    height: 35px;
  }

  .winners .pageheader,
  .pageheader p {
    margin: 2px 0 20px 0;
  }
  .winners {
    flex-direction: column;
  }
  h6 {
    font-size: 14px;
  }

  .textblock strong {
    margin-bottom: 5px;
  }

  #buy-credit-desktop,
  #how-play-desktop,
  #fraud-awareness-desktop,
  #live_draw-desktop,
  #about_desktop,
  #draw_boxes_desktop,
  #withdraw_desktop {
    display: none !important;
  }

  #buy-credit-mobile,
  #how-play-mobile,
  #fraud-awareness-mobile,
  #live_draw-mobile,
  #about_mobile,
  #withdraw_mobile {
    display: block !important;
  }

  .textblock {
    margin: 0px 0 20px 0;
  }

  h2.page-sub-title,
  .textblock strong,
  .textblock h2,
  .textblock h1,
  .page-faq .pageheader p,
  .pageheader,
  .pageheader p,
  .videos_heading p strong {
    font-size: 20px !important;
  }
  .textblock p,
  .textblock a {
    font-size: 15px !important;
    line-height: 27px;
  }
  .pagebanner {
    margin: 20px 0;
  }
}

@media (max-width: 576px) {
  .pagination ul li {
    width: 27px;
    height: 27px;
  }
  .pagination ul li a {
    font-size: 11px;
  }

  h2.page-sub-title,
  .textblock strong,
  .textblock h2,
  .textblock h1,
  .page-faq .pageheader p,
  .pageheader,
  .pageheader p,
  .videos_heading p strong {
    font-size: 18px !important;
  }
  .pageheader {
    font-size: 20px;
  }

  .page_winners .pageoffers,
  #pageoffers_desktop,
  .page_community .pagebanner,
  .page_winners .pagebanner img {
    display: none;
  }

  .textblock h5 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .pagination ul li {
    width: 24px;
    height: 24px;
  }
}
